<template>
  <!-- 自定义菜单 -->
    <div class="box-card" style="text-align: left;">
        <el-form :model="addForm" ref="addFormRefs" :rules="addFormRules" label-width="120px" class="addForm">
            <el-form-item label="菜单包名称:" prop="resource_name">
                <el-input placeholder="请输入菜单包名称" autocomplete="off" maxlength="15" :value="addForm.resource_name"
                    @input="(e) => (addForm.resource_name = validSpace(e))"></el-input>
            </el-form-item>
            <el-form-item label="配置菜单:" prop="children">
                <Config ref="ConfigRef" :menuList="addForm.children"></Config>
            </el-form-item>
        </el-form>
        <lbButton type="warning" fill class="checkButton3" @click="openMenusTemplate">从模板导入</lbButton>
        <!-- <lbButton type="goBack" icon="back" class="checkButton2" @click="defaultForm">返 回</lbButton> -->
        <lbButton type="default" fill icon="confirm" class="checkButton" @click="onSubmit()" v-preventReClick>保 存</lbButton>
        <!-- 模板弹框 -->
        <lebo-dialog append-to-body title="自定义菜单模板" :isShow="isMenusTemplate" width="30%" @close="isMenusTemplate = false"
            footerSlot>
            <el-table class="tablestyle" ref="multipleTable" :data="menusTemplateList" align="center" header-align="center"
                tooltip-effect="dark" height="600" border stripe>
                <el-table-column prop="resource_name" label="平台菜单包"></el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <lbButton type="info" icon="daoru1" hint="确定导入" @click="importMenus(scope.row)"></lbButton>
                    </template>
                </el-table-column>
            </el-table>
        </lebo-dialog>
    </div>
</template>
<script>
import systemApi from '@/api/systemapi'
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
import Config from '../../config.vue'
export default {
  components: {
    Config
  },
  props: ['id'],
  data () {
    return {
      addForm: {
        resource_name: '',
        children: []
      },
      addFormRules: {
        pack_name: [
          { required: true, message: '请输入菜单包名称', trigger: 'blur' }
        ],
        children: [{ required: true, message: '请配置菜单', trigger: 'blur' }]
      },
      isMenusTemplate: false,
      menusTemplateList: [] // 模板列表
    }
  },
  created () {
    if (this.id) {
      this.fnGetSysResourceMenusDetail()
    }
  },
  mounted () { },
  computed: {
    ...mapState(['queryObj', 'queryStatus']),
    ...mapGetters(['isAdmin', 'getOptions', 'getWayName'])
  },
  watch: {},
  methods: {
    ...mapMutations(['setQueryStatus']),
    // 获取详情
    async fnGetSysResourceMenusDetail () {
      const res = await systemApi.getSysResourceMenusDetail({
        _id: this.id
      })
      console.log(res)
      if (res && res.Code === 200) {
        this.addForm.resource_name = res.Data.resource_name
        this.addForm.children = res.Data.children
        // this.addForm._id = res.Data._id
      }
    },
    // 返回
    defaultForm () {
      this.setQueryStatus(2)
      this.$emit('closeDialog', false)
    },
    // 保存提交
    onSubmit () {
      this.addForm.children = this.$refs.ConfigRef.configList
      this.$refs.addFormRefs.validate((valid) => {
        if (valid) {
          if (this.id) {
            this.fnUpdateSysResourceMenus()
          } else {
            this.fnAddSysResourceMenus()
          }
        }
      })
    },
    async fnAddSysResourceMenus () {
      console.log(JSON.stringify(this.addForm))
      const res = await systemApi.addSysResourceMenus(this.addForm)
      console.log(res)
      if (res.Code === 200) {
        this.$emit('closeDialog', false)
      }
    },
    async fnUpdateSysResourceMenus () {
      var obj = this.addForm
      console.log(JSON.stringify(this.addForm.children))
      obj._id = this.id
      const res = await systemApi.updateSysResourceMenus(obj)
      console.log(res)
      if (res.Code === 200) {
        this.defaultForm()
      }
    },
    // 点击从模板导入
    async openMenusTemplate () {
      const res = await systemApi.getMenusTemplateList()
      console.log(res)
      this.menusTemplateList = res && res.Code === 200 ? res.Data : []
      this.isMenusTemplate = true
    },
    // 导入菜单
    importMenus (item) {
      this.addForm = item
      this.isMenusTemplate = false
    }
  }
}
</script>
<style scoped lang="less">
.addForm {

    /deep/ .el-select,
    /deep/.el-input {
        width: 400px;
        margin-right: 10px;
    }
}

.box-card {
    position: relative;
    padding: 0 20px 50px 0;
    // padding-bottom: 50px;
    text-align: left;
    overflow: auto;
    box-sizing: border-box;
    // min-height: 500px;

    .checkButton {
        position: absolute;
        right: 0px;
        bottom: 0;
    }

    .checkButton2 {
        position: absolute;
        right: 100px;
        bottom: 0;

    }

    .checkButton3 {
        position: absolute;
        left: 0px;
        bottom: 0;

    }
}
</style>
